<template>
	<div id="Extension">
		<div class="Ext_Top">
			<div class="Ext_Top_item">
				<p>推广等级:<span>Lv{{UserData.promotion_level}}</span></p>
			</div>
			<div class="Ext_Top_item">
				<p>推广返利:<span>{{ExtRebate}}</span>%</p>
			</div>
			<div class="Ext_Top_item">
				<p>成功推广人数:<span>{{UserData.be_invite_number}}</span>人</p>
			</div>
			<div class="Ext_Top_item">
				<p>累计获得奖励:<span><img src="@/assets/images/public/Gold.png" alt="">{{UserData.gain}}</span></p>
			</div>
			<div class="Ext_center">
			<van-field center clearable label="推广链接" readonly :value="InviteLink">
				<template #button>
					<van-button size="small" type="info" v-clipboard:copy="InviteLink" v-clipboard:success="copy" v-clipboard:error="onError" style="color:black !important">复制</van-button>
				</template>
			</van-field>
			<van-field class="smll" center clearable label="推广码" readonly :value="UserData.invite_code">
				<template #button>
					<van-button size="small" type="info" v-clipboard:copy="UserData.invite_code" v-clipboard:success="copy"
					 v-clipboard:error="onError" style="color:black !important">复制</van-button>
				</template>
			</van-field>
			<div></div>
		</div>
		</div>
	
		<div class="Ext_bottom">
			<div class="Ext_tit">推广规则</div>
			<p>1.每个新用户通过推广链接进入网站充值成功，都会给推广人带来固定比例的佣金返利，佣金比例以页面显示为准。<br />

				2.如果用户通过你的推广链接进入网站充值并未给你带来佣金返利，代表此用户不是新用户。<br />

				3.严禁通过不正当方式刷取本活动奖励，任何作弊行为一经发现，将直接扣除作弊用户获得的所有佣金返利；情节严重的，将直接采取封号等处理方式。<br />

				声明：985csgo将根据实际运营情况保留随时调整返佣比例的权利，以上活动最终解释权归985csgo所有!</p>
		</div>

		<div class="key_box">
			<div class="key_box_tit">推广活动</div>
			<div class="key_tit">
				你通过累积活动获得了<span>{{welfare_key_total}}</span>把钥匙<br />
				<p v-for="(item,index) in welfare_promotion" :key="index">{{item.box.name}}钥匙<span>{{item.my_times}}</span>把{{index | indexGL}}</p>
			</div>
			<div class="key_list">
				<div class="key_item" v-for="(item,index) in welfare_promotion" :key="index">
					<p>{{item.name}}</p>
					<i>------------------------</i>
					<span>+1🔑{{item.box.name}}箱子钥匙</span>
				</div>

			</div>
		</div>

		<div class="Grade">
			<div class="Grade_tit">推广等级说明</div>
			<div class="Grade_item">
				<p>等级</p>
				<p>下一级需要</p>
				<p>您的收益比</p>
				<!-- <p></p> -->
			</div>
			<div class="Grade_item" v-for="(item,index) in GradeList" :key="index + 'a'">
				<p>{{item.name}}</p>
				<p><img src="@/assets/images/public/Gold.png" alt="">{{item.total}}</p>
				<p>{{item.rebate}}%</p>
				<!-- <p>
					<van-button size="mini" type="info" @click="ChangeGradeshow(index)">升级要求</van-button>
				</p> -->
			</div>
		</div>
		<!-- 推广等级升级要求遮罩层 -->
		<van-overlay :show="Gradeshow == index" :key="index" v-for="(item,index) in GradeList">
			<div class="wrapper">
				<div class="LogOut_box">
					<div class="close" @click="Gradeshow = null"><img src="@/assets/images/public/close.png" alt=""></div>
					<div class="tit">Lv{{item.level}}升级要求</div>
					<div class="text">{{item.description}}</div>
				</div>
			</div>
		</van-overlay>

		<div class="Subordinate">
			<div class="Subordinate_tit">成功推广人数</div>
			<div class="Subordinate_list">
				<div class="Subordinate_item">
					<p>推广时间</p>
					<p>用户昵称</p>
					<p>充值金额</p>
					<p>返佣收入</p>
				</div>
				<div class="Subordinate_item" v-for="(item,index) in SubordinateList" :key="index">
					<p>{{ item.created_at }}</p>
					<p>{{item.name | StrIntercept}}</p>
					<p><img src="@/assets/images/public/Gold.png" alt="">{{item.charge_bean}}</p>
					<p><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</p>
				</div>
			</div>
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" @current-change="handleCurrentChange"
			 :pager-count='5' prev-text="上一页" next-text="下一页" small>
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {
		Promote,
		Be_inviter
	} from '@/network/api.js'
	export default {
		name: 'Extension',
		data() {
			return {
				UserData: {}, //推广个人数据
				ExtRebate: '', //个人返佣比例
				GradeList: [], //推广等级列表
				Gradeshow: null, //推广等级升级要求遮罩层
				SubordinateList: [], //推广明细列表
				total: 0, //推广明细列表总数据
				per_page: 0, //一页数据数量
				PageNum: 1, //页码
				welfare_promotion: [], //钥匙列表
				welfare_key_total:0,//钥匙总量
			}
		},
		created() {
			this.GetPromote()
			this.GetBe_inviter()
		},

		filters: {
			StrIntercept(val) {
				return val.length > 7 ? val.substr(0, 6) + '...' : val
			},
			indexGL(v) {
				if (v == 4) {
					return '。'
				} else {
					return ','
				}
			}
		},

		methods: {
			//个人推广数据
			GetPromote() {
				Promote().then((res) => {
					// console.log(res.data.data)
					this.welfare_promotion = res.data.data.welfare_promotion
					this.UserData = res.data.data.user
					this.welfare_key_total = res.data.data.user.welfare_key_total
					this.ExtRebate = res.data.data.user.promotion.rebate
					this.GradeList = res.data.data.list
				})
			},

			//复制文本
			copy(e) {
				// console.log(e)
				this.$notify({
					type: 'success',
					message: '复制成功'
				})
			},
			onError(e) {
				// console.log(e)
				this.$notify({
					type: 'danger',
					message: '复制失败'
				})
			},

			//推广升级要求遮罩层
			ChangeGradeshow(index) {
				this.Gradeshow = index
			},

			//推广明细列表分页
			handleCurrentChange(val) {
				// console.log(val)
				this.PageNum = val
				this.GetBe_inviter()
			},

			//推广明细列表
			GetBe_inviter() {
				Be_inviter(this.PageNum).then((res) => {
					// console.log(res.data.data)
					this.total = res.data.data[0].total
					this.per_page = res.data.data[0].per_page
					this.SubordinateList = res.data.data[0].data
				})
			}

		},
		computed: {
			//推广链接
			InviteLink() {
				return 'https://m.985csgo.com/?ref=' + this.UserData.invite_code
			}
		}
	}
</script>

<style lang="scss">
	#Extension {
		width: 100%;
		padding: 10px;
		box-sizing: border-box;
		background: url('../../assets/images/q2/tui/bacbac.png') no-repeat center;
		background-size: 100% 100% !important;
		background-attachment: fixed;
		.Ext_Top {
			width: 100%;
			background: #20222b;
			border-radius: 4px;
			display: flex;
			flex-wrap: wrap;
			background: url('../../assets/images/q2/tui/topbac.png') no-repeat center;
			background-size: 100% 100%;
			.Ext_Top_item {
				width: 50%;
				height: 50px;
				display: flex;
				align-items: center;
				color: #fff;
				padding-left: 10px;
				box-sizing: border-box;

				p {
					font-size: 14px;

					span {
						font-size: 16px;
						font-weight: bold;
						margin-left: 5px;

						img {
							width: 18px;
							vertical-align: middle;
							margin-bottom: 2px;
							margin-right: 2px;
						}
					}
				}

			}
		}

		.Ext_center {
			width: 100%;
			padding-bottom: 20px;
			// background: #20222b;
			border-radius: 4px;

			.van-cell {
				background: none;
				margin-top: 10px;

				.van-field__label {
					width: 50px;
					color: #fff;
					font-size: 12px;
				}

				.van-field__control {
					color: #fff;
				}

				&.smll {
					width: 70%;
				}
			}

			.van-cell::after {
				background: #fff;
			}
		}

		.Ext_bottom {
			width: 100%;
			margin-top: 12px;
			padding-bottom: 10px;
			// background:#20222b;
			border-radius: 4px;
			overflow: hidden;
			color: #fff;
			background: url('../../assets/images/q2/tui/bac.png') no-repeat center;
			background-size: 100% 100%;
			.Ext_tit {
				font-size: 16px;
				font-weight: bold;
				text-align: center;
				margin: 10px 0;
			}

			p {
				width: 100%;
				padding: 0 10px;
				box-sizing: border-box;
				line-height: 1.7em;
				font-size: 12px;
			}
		}

		.key_box {
			width: 100%;
			background: url('../../assets/images/q2/tui/bac.png') no-repeat center;
			background-size: 100% 100%;		
			border-radius: 4px;
			margin: 10px 0;
			padding: 10px;
			box-sizing: border-box;
			.key_box_tit{
				font-size: 16px;
				font-weight: bold;
				text-align: center;
				color: #fff;
			}
			.key_tit {
				text-align: center;
				line-height: 1.75em;
				font-size: 14px;
				margin-top: 5px;
				color: #c6c4d8;

				p {
					display: inline;
				}

				span {
					color: rgb(255, 211, 34);
				}
			}

			.key_list {
				width: 100%;
				margin-top: 20px;
				
				.key_item {
					width: 100%;
					height: 45px;
					display: flex;
					justify-content: center;
					align-items: center;

					p {
						font-size: 12px;
						color: #fff;

						span {
							color: rgb(255, 211, 34);
							font-weight: bold;
						}
					}

					i {
						font-style: normal;
						color: #fff;
						margin: 0 20px;
					}

					span {
						color: rgb(255, 211, 34);
						font-size: 12px;

						img {
							width: 26px;
							vertical-align: middle;
							margin-bottom: 2px;
							margin-left: 2px;
							margin-right: 2px;
						}
					}
				}
			}
		}

		.Grade {
			width: 100%;
			margin-top: 12px;
			padding: 0 10px 15px 20px;
			background: url('../../assets/images/q2/tui/bac.png') no-repeat center;
			background-size: 100% 100%;
			border-radius: 4px;
			overflow: hidden;
			color: #fff;
			box-sizing: border-box;

			.Grade_tit {
				font-size: 16px;
				font-weight: bold;
				text-align: center;
				margin: 10px 0;
			}

			.Grade_item {
				width: 100%;
				display: flex;
				height: 40px;

				&:nth-child(2n) {
					background: linear-gradient(90deg, rgba(255, 162, 24, 0), rgba(255, 162, 24, .19) 52%, rgba(255, 162, 24, 0));
				}

				p {
					flex: 1;
					font-size: 14px;
					display: flex;
					align-items: center;

					img {
						width: 15px;
						vertical-align: middle;
					}

				}

				&:nth-child(2) {
					background: none;

					p {
						font-size: 12px;
					}
				}
			}
		}

		.Subordinate {
			width: 100%;
			margin-top: 12px;
			padding: 0 10px 20px;
			background: url('../../assets/images/q2/tui/bac.png') no-repeat center;
			background-size: 100% 100%;
			border-radius: 4px;
			overflow: hidden;
			color: #fff;
			box-sizing: border-box;

			.Subordinate_tit {
				font-size: 16px;
				font-weight: bold;
				text-align: center;
				margin: 10px 0;
			}

			.Subordinate_list {
				width: 100%;
				padding-left: 20px;
				box-sizing: border-box;
				margin-bottom: 15px;
				min-height: 100px;

				.Subordinate_item {
					width: 100%;
					display: flex;
					height: 40px;

					&:nth-child(2n) {
						background: linear-gradient(90deg, rgba(255, 162, 24, 0), rgba(255, 162, 24, .19) 52%, rgba(255, 162, 24, 0));
					}

					p {
						flex: 1;
						font-size: 10px;
						display: flex;
						align-items: center;

						img {
							width: 15px;
							vertical-align: middle;
						}

					}

					&:first-child {
						p {
							font-size: 12px;
						}
					}

				}

			}
		}
	}
</style>
